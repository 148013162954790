import { createSlice } from 'redux-starter-kit';
import config from 'config';

// import { toast } from 'react-toastify';
// Get the default site from config
const defaultSite = config.siteSwitcher.sites[0];

const slice = createSlice({
  slice: 'global',
  initialState: {
    loading: false,
    redirect: false,
    site: defaultSite,
    menuOpen: false,
    filters: {}
  },
  reducers: {
    loading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setRedirect(state, action) {
      state.redirect = action.payload ? action.payload : true;
    },
    cancelRedirect(state) {
      state.redirect = null;
    },
    setSite(state, action) {
      state.site = action.payload;
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
    clearFilters(state, action) {
      state.filters = action.payload;
    }
  }
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;
// Extract and export each action creator by name
export const {
  loading,
  stopLoading,
  setRedirect,
  cancelRedirect,
  setSite,
  toggleMenu,
  setFilter,
  clearFilters
} = actions;

export const loadingStart = () => async dispatch => {
  dispatch(loading());
};

export const loadingFinish = () => async dispatch => {
  dispatch(stopLoading());
};

export const switchSite = site => async dispatch => {
  dispatch(setSite(site));
  localStorage.setItem('site', JSON.stringify(site));
  // toast.success(`Site has been switched to ${site.name}.`);
  window.location.reload();
};

export const checkSite = () => async dispatch => {
  const hasSiteStored = localStorage.getItem('site');
  if (hasSiteStored) {
    dispatch(setSite(JSON.parse(hasSiteStored)));
  } else {
    localStorage.setItem('site', defaultSite);
  }
};

export const redirect = url => async dispatch => {
  dispatch(setRedirect(url));
  setTimeout(() => {
    dispatch(cancelRedirect());
  }, 1000);
};
export const toggleMenuState = () => async dispatch => {
  dispatch(toggleMenu());
};

export const filter = (key, value) => dispatch => {
  dispatch(setFilter(key, value));
};

export const resetFilter = def => dispatch => {
  dispatch(clearFilters(def));
};

// Export the reducer, either as a default or named export
export default reducer;
