/* eslint-disable no-undef */
import React from 'react';

import { Router } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import ScrollToTop from 'components/ScrollToTop';
import HeaderBar from 'components/HeaderBar';
import MainMenu from 'components/MainMenu';
import { useMediaQuery } from 'react-responsive';
import { Layout, BackTop } from 'antd';

import { ToastContainer } from 'react-toastify';

import Routes from '../../routes';

const history = createBrowserHistory();
const { Content, Header } = Layout;

const RouteWrapper = () => {
  const authLoading = useSelector(state => state.auth.loading);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const isMobile = useMediaQuery({
    query: '(max-device-width: 768px)'
  });

  return (
    <Router history={history}>
      <ScrollToTop />

      <ToastContainer className="toast-container" toastClassName="nucleus-toastify" />

      {isAuthenticated && !authLoading && (
        <Layout style={{ minHeight: '100vh' }}>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1000,
              width: '100%',
              height: 55,
              lineHeight: '55px',
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <HeaderBar />
          </Header>

          <MainMenu isMobile={isMobile} />

          <Layout>
            <BackTop visibilityHeight={100} />
            <Content>
              <Routes />
            </Content>
          </Layout>
        </Layout>
      )}
      {!isAuthenticated && !authLoading && <Routes />}
    </Router>
  );
};

export default RouteWrapper;
