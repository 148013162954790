import React from 'react';
import CrudEntryWrapper from 'components/CrudHOC';
import FormWrapper from 'components/FormWrapper';
import ContentWrapper from 'components/ContentWrapper';

import Form from './Form';
import { setup } from './setup';

const EntryWrapper = (props) => {
  const { saveEntry, data, resetForm } = props;

  const displayTitle = `${data.first_name} ${data.last_name}`;

  const tabs = [
    {
      label: 'Details',
      component: <Form />,
    },
  ];

  // const permissions = useSelector(state => state.users.entry.permissions);

  return (
    <ContentWrapper fullWidth={false}>
      <FormWrapper
        setup={setup}
        reset={resetForm}
        displayTitle={displayTitle}
        tabs={tabs}
        initialValues={data}
        onSubmit={saveEntry}
        seo={false}
      />
    </ContentWrapper>
  );
};

const EntryWrapperWithCrud = CrudEntryWrapper(EntryWrapper, setup);

export default EntryWrapperWithCrud;
