import Http from 'common/utils/Http';
import { toast } from 'react-toastify';

export const fetchCategories = async () => {
  try {
    const data = await Http.get(`categories`);

    return data.data.data;
  } catch (error) {
    toast.error('Error fetching categories');
  }
};
