import React from 'react';
import { Field } from 'redux-form';
import { Card, Row, Col } from 'antd';
import * as validations from 'common/utils/Validations';

import TextInput from 'fields/TextField';
import Select from 'fields/Select';

const EditFormWrapper = () => (
    <Card title='Personal Information'>
        <Row gutter={16}>
            <Col xs={24} sm={12}>
                <Field
                    component={TextInput}
                    placeholder='Placeholder'
                    label='Label'
                    name='field_name'
                    required
                    validate={validations.required}
                />
            </Col>
        </Row>
        <Row gutter={16}>
            <Col xs={24} sm={12}>
                <Field
                    component={Select}
                    options={[]}
                    valueKey='id'
                    labelKey='name'
                    placeholder='Select User Role'
                    label='User Role'
                    name='role'
                    required
                    validate={validations.required}
                />
            </Col>
        </Row>
    </Card>
);

export default EditFormWrapper;
