import React from 'react';
import { Icon } from 'antd';

const adminMenuItems = [
  {
    group: 'System',
    icon: <Icon type="setting" />,
    items: [
      {
        title: 'Admin Users',
        href: '/administrators',
      },
    ],
  },
];

export default adminMenuItems;
