/* eslint-disable */
import React, { useEffect } from 'react';
import AddBlock from '../AddBlock';
import { Field, FieldArray, arrayRemove, change } from 'redux-form';
import RichEditor from '../TextBlock';
import TextInput from '../TextInput';
import FileUpload from '../ImageBlock';
import GridBlock from '../GridBlock';
import { useDispatch } from 'react-redux';

//const OtherComponent = React.lazy(() => import("../GridBlock"));

const renderBlocks = ({ fields, config }) => {
  const dispatch = useDispatch();
  const moveUp = index => {
    fields.move(index, index - 1);
  };
  const moveDown = index => {
    fields.move(index, index + 1);
  };
  const remove = index => {
    if (fields.length === 1) {
      dispatch(change('EditForm', 'blocks', new Array()));
    } else {
      dispatch(arrayRemove('EditForm', 'blocks', index));
    }
  };

  if (fields.length === 0) {
    return <AddBlock config={config} />;
  } else {
    return fields.map((block, index) => {
      switch (fields.get(index).type) {
        case 'richText':
          return (
            <Field
              component={RichEditor}
              name={`${block}.value`}
              label={`${block}.label`}
              props={{ index }}
              config={config}
              moveUp={moveUp}
              moveDown={moveDown}
              remove={remove}
              key={`block=${index}`}
            />
          );
          break;
        case 'text':
          return (
            <Field
              component={TextInput}
              name={`${block}.value`}
              label={fields.get(index).label}
              props={{ index }}
              config={config}
              moveUp={moveUp}
              moveDown={moveDown}
              remove={remove}
              key={`block=${index}`}
            />
          );
          break;
        case 'image':
          return (
            <Field
              component={FileUpload}
              name={`${block}.value`}
              label={`${block}.label`}
              props={{ index }}
              config={config}
              moveUp={moveUp}
              moveDown={moveDown}
              remove={remove}
              key={`block=${index}`}
            />
          );
          break;
        case 'grid':
          return (
            <FieldArray
              component={GridBlock}
              label={`${block}.label`}
              type={fields.get(index).type.split('-')[1]}
              columns={fields.get(index).columns}
              rows={fields.get(index).rows}
              blocks={fields.get(index).blocks}
              name={`${block}.value`}
              props={{ index }}
              config={config}
              moveUp={moveUp}
              moveDown={moveDown}
              remove={remove}
              key={`block=${index}`}
            />
          );
          break;
      }
    });
  }
};

export default renderBlocks;
