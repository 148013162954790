import axios from 'axios';
import config from 'config';

const environment = process.env.REACT_APP_ENVIRONMENT;

let api = config.api.local;

if (environment === 'development') {
  api = config.api.local;
}
if (environment === 'staging') {
  api = config.api.staging;
}
if (environment === 'production') {
  api = config.api.production;
}

export const baseApi = api;

axios.defaults.baseURL = api;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default axios;
