import React from 'react';

import { Input, Form } from 'antd';

const TextInput = props => {
  const {
    required,
    input,
    meta,
    placeholder,
    label,
    type,
    extra,
    help,
    characterLimit = null,
    ...rest
  } = props;

  const [value, setValue] = React.useState('');

  const handleChange = value => {
    if (characterLimit) {
      if (value.length <= characterLimit) {
        setValue(value);
        input.onChange(value);
      }
    } else {
      input.onChange(value);
    }
  };

  React.useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  /* eslint-disable */
  const renderField = () => {
    switch (type) {
      case 'textarea':
        return (
          <Input.TextArea
            label={label}
            {...rest}
            placeholder={placeholder}
            value={input.value}
            onChange={e => input.onChange(e.target.value)}
          />
        );
        break;
      case 'password':
        return (
          <Input.Password
            label={label}
            {...rest}
            placeholder={placeholder}
            value={input.value}
            onChange={e => input.onChange(e.target.value)}
          />
        );
        break;
      default:
        return (
          <Input
            label={label}
            {...rest}
            placeholder={placeholder}
            value={input.value}
            onChange={e => handleChange(e.target.value)}
          />
        );
    }
  };

  return (
    <Form.Item
      required={required}
      extra={
        extra
          ? extra
          : characterLimit &&
            `${characterLimit && `${value.length} / ${characterLimit} characters`}`
      }
      validateStatus={meta.invalid && meta.submitFailed ? 'error' : ''}
      help={meta.invalid && meta.submitFailed ? meta.error : help}
      hasFeedback={meta.invalid && meta.submitFailed}
      label={label}
    >
      {renderField()}
    </Form.Item>
  );
};

export default TextInput;
