import React from 'react';

const DevTape = () => {
  return (
    <div
      style={{
        background:
          'repeating-linear-gradient(135deg,#f1c40f,#f1c40f 25px,#4a4a4a 25px,#4a4a4a 50px)',
        width: '100vw',
        height: 13,
        color: 'white',
        padding: 0,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <span
        style={{
          background: '#4a4a4a',
          fontSize: 12,
          fontWeight: 500,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        Development test mode. Bypassing authentication.
      </span>
    </div>
  );
};

export default DevTape;
