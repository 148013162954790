import { createSlice } from '@reduxjs/toolkit';
import Http from 'common/utils/Http';
import { toast } from 'react-toastify';

const testMode = process.env.REACT_APP_TEST_MODE === 'true' ? true : false;

const slice = createSlice({
  name: 'auth',
  initialState: {
    permissions: [],
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false
  },
  reducers: {
    authStatus(state, action) {
      state.isAuthenticated = action.payload;
      state.loading = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    authLogout(state) {
      state.user = null;
      state.loading = false;
    },
    loginSuccess(state, action) {
      state.error = null;
    },
    loginError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    authLoading(state) {
      state.loading = true;
    }
  }
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export function checkAuth() {
  return async dispatch => {
    const hasToken = localStorage.getItem('token');
    if (hasToken) {
      Http.defaults.headers.common.Authorization = `Bearer ${hasToken}`;
    }
    dispatch(actions.authLoading());
    if (testMode) {
      dispatch(
        actions.setUser({
          first_name: 'Test',
          lasT_name: 'User',
          email: 'test@test.com',
          id: 1
        })
      );
      dispatch(actions.authStatus(true));
    } else {
      Http.get('/auth/admin-users/current')
        .then(res => {
          dispatch(actions.setUser(res.data.data));
          dispatch(actions.authStatus(true));
        })
        .catch(() => {
          toast.warning('Could not get current logged in user');
          dispatch(actions.authStatus(false));
          dispatch(actions.setUser(null));
          //window.location.href = '/?auth=false';
        });
    }
  };
}

export function login(values) {
  return async dispatch => {
    dispatch(actions.authLoading());

    Http.post('/auth/admin-users/login', values)
      .then(res => {
        localStorage.setItem('token', res.data.success.token);
        Http.defaults.headers.common.Authorization = `Bearer ${res.data.success.token}`;

        dispatch(actions.authStatus(true));
      })
      .catch(err => {
        if (!err.response) {
          toast.warning('Error logging in');
          dispatch(actions.loginError('No response from server'));
          dispatch(actions.authStatus(false));
        } else {
          toast.warning('Error logging in');
          dispatch(actions.loginError(err.response.data.error.message));
          dispatch(actions.authStatus(false));
        }
      });
  };
}

export function logout() {
  return async dispatch => {
    dispatch(actions.authLoading());

    Http.post('/auth/admin-users/logout')
      .then(() => {
        dispatch(actions.authStatus(false));
        toast.success('You have been logged out');
        localStorage.removeItem('token');
      })
      .catch(() => {
        toast.warning('Error logging out');
        dispatch(actions.authStatus(false));
        localStorage.removeItem('token');
      });
  };
}

// Export the reducer, either as a default or named export
export default reducer;
