import React from 'react';
import { Field } from 'redux-form';
import TextInput from 'fields/TextField';
import { Card, Row, Col } from 'antd';

const SEOFields = () => (
  <Card title="SEO">
    <Row gutter={16}>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          placeholder="Meta Title"
          label="Meta Title"
          name="seo[title]"
          variant="outlined"
        />
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={12}>
        <Field
          component={TextInput}
          placeholder="Meta Description"
          label="Meta Description"
          name="seo[description]"
          variant="outlined"
        />
      </Col>
    </Row>
  </Card>
);

export default SEOFields;
