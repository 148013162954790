import React from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';

const BasicDatePicker = (props) => {
  const {
    meta, label, input, required, help, type, ...rest
  } = props;

  const handleChange = (date) => {
    props.input.onChange(moment(date).format());
  };
  const handleRangeChange = (date) => {
    const from = moment(date[0]).format();
    const to = moment(date[1]).format();
    props.input.onChange([from, to]);
  };

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={meta.invalid && meta.submitFailed ? 'error' : ''}
      help={meta.invalid && meta.submitFailed ? meta.error : help}
      hasFeedback={meta.invalid && meta.submitFailed}
    >
      {type === 'rangepicker' ? (
        <DatePicker.RangePicker
          format="DD/MM/YY"
          style={!props.style ? { width: '100%' } : props.style}
          {...rest}
          value={input.value ? moment(input.value) : null}
          onChange={handleRangeChange}
        />
      ) : (
        <DatePicker
          onChange={handleChange}
          style={!props.style ? { width: '100%' } : props.style}
          {...rest}
          value={input.value ? moment(input.value) : null}
          format="DD/MM/YYYY"
        />
      )}
    </Form.Item>
  );
};

export default BasicDatePicker;
