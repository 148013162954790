import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import Http from 'common/utils/Http';
import Dropzone from 'react-dropzone';
import { Form, Button, Modal, Spin, Popconfirm } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { toast } from 'react-toastify';

import Sortable from './components/Sortable';

import './index.scss';
/* eslint-disable */
const FilesDropzone = props => {
  const { className, input, meta, accept, max = 9999, type, ...rest } = props;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState({
    image: null,
    open: false
  });

  const handleDrop = files => {
    // Create a temp store of images for processing
    setAcceptedFiles(files);
    setLoading(true);

    const newUploadedFiles = props.input.value ? [...props.input.value] : [];

    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);

      return Http.post(`file-upload/${file.type === 'text/csv' ? 'csv' : 'image'}`, formData)
        .then(response => {
          const { data } = response;

          newUploadedFiles.push(data);
        })
        .catch(() => {
          toast.error('Error uploading file');
        });
    });

    axios.all(uploaders).then(() => {
      setFiles(newUploadedFiles);
      setLoading(false);
      props.input.onChange(newUploadedFiles);
    });
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const removeImage = index => {
    const filesArray = [...files];
    filesArray.splice(index, 1);
    setFiles(filesArray);
    props.input.onChange(filesArray);
  };

  const onSortEnd = files => {
    setFiles(files);
  };

  const openPreview = image => {
    setPreview({
      image,
      open: true
    });
  };

  const closePreview = () => {
    setPreview({
      image: null,
      open: false
    });
  };

  useEffect(() => {
    setFiles(input.value);
  }, [input.value]);

  return (
    <div {...rest}>
      <div style={{ marginBottom: 10 }}>
        {max === 9999 ? (
          <span>{`${files ? files.length : 0} file(s) uploaded`}</span>
        ) : (
          <span>{`Maximum ${max} files. ${files ? files.length : 0} file(s) uploaded`}</span>
        )}
      </div>
      <Form.Item
        required={props.required}
        extra={props.extra}
        validateStatus={props.meta.invalid && props.meta.submitFailed ? 'error' : ''}
        help={props.meta.invalid && props.meta.submitFailed ? props.meta.error : props.help}
        hasFeedback={props.meta.invalid && props.meta.submitFailed}
      >
        {files.length !== max && (
          <Dropzone
            onDrop={handleDrop}
            multiple={max > 1}
            accept={accept}
            disabled={files && files.length >= max}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={clsx({
                  'nucleus--dropzone': true,
                  'dz-drag-active': isDragActive,
                  'dz-disabled': loading || (files && files.length >= max),
                  'dz-error': props.meta.invalid && props.meta.submitFailed
                })}
                {...getRootProps()}
              >
                <Button icon="upload" type="primary" disabled={loading}>
                  Upload
                </Button>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        )}

        {loading && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Spin />{' '}
            <span style={{ fontWeight: 500, marginLeft: 10 }}>Uploading files, please wait</span>
          </div>
        )}

        {files && files.length > 0 && (
          <>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <div>
                <Sortable
                  files={files}
                  removeImage={removeImage}
                  onSortEnd={onSortEnd}
                  openPreview={openPreview}
                />
              </div>
            </PerfectScrollbar>
            <div className="dz-actions">
              <Popconfirm
                onConfirm={handleRemoveAll}
                title="Are you sure you want to remove all files?"
              >
                <Button size="small">Remove all</Button>
              </Popconfirm>
            </div>
          </>
        )}

        <Modal
          visible={preview.open}
          onCancel={closePreview}
          onOk={closePreview}
          okText="Close"
          width={1000}
        >
          <img alt="preview" style={{ width: '100%' }} src={preview.image} />
        </Modal>
      </Form.Item>
    </div>
  );
};

export default FilesDropzone;
