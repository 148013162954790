import React from 'react';
import styled from 'styled-components';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Http from 'common/utils/Http';
import EditorJS from '@editorjs/editorjs';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

const Editor = styled.div`
  .ce-block__content,
  .ce-toolbar__content {
    max-width: 1000px !important;
    font-size: 1.1em;
  }
`;

const ContentEditor = props => {
  const { input } = props;

  const isLoading = useSelector(state => state.global.loading);

  const saveContent = data => {
    input.onChange(data);
  };

  const initialise = () => {
    const editor = new EditorJS({
      placeholder: 'Begin typing...',
      onChange: () => {
        editor.save().then(data => saveContent(data));
      },
      data: props.input.value,
      tools: {
        header: {
          class: Header,
          inlineToolbar: true
        },
        list: List,
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
              instagram: true,
              twitter: true
            }
          }
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file) {
                const formData = new FormData();
                formData.append('file', file);
                return Http.post('/file-upload/image', formData).then(res => {
                  return {
                    success: 1,
                    file: {
                      url: res.data.url
                      // any other image data you want to store, such as width, height, color, extension, etc
                    }
                  };
                });
              }
            }
          }
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3
          }
        }
      }
    });
  };

  React.useEffect(() => {
    if (props.meta.initial && !isLoading && props.match.params.type === 'edit') {
      initialise();
    }
    if (props.match.params.type === 'create') {
      initialise();
    }
  }, [props.meta.initial, props.match.params.type]);

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Editor id="editorjs"></Editor>
    </div>
  );
};

export default withRouter(ContentEditor);
