/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Http from "common/utils/Http";
import arrayMove from "array-move";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";

import { toast } from "react-toastify";
import Base64ToFile from "common/utils/Base64ToImage";

import { Button, Typography, Progress } from "antd";

const FilesDropzone = props => {
  const { className, input, meta, label, ...rest } = props;
  const dispatch = useDispatch();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const uploadImage = image => {
    const formData = new FormData();

    setLoading(true);

    formData.append("file", image);

    closeModal();

    return Http.post(
      `${props.site ? props.site : ""}/file-upload/image`,
      formData
    )
      .then(response => {
        const { data } = response;

        setLoading(false);
        setUploadedFile(data);
        input.onChange(data);
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleDrop = useCallback(acceptedFiles => {
    uploadImage(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    setUploadedFile(input.value);
  }, [input.value]);

  const handleRemoveAll = () => {
    setUploadedFile({});
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false
  });

  return (
    <div {...rest} className={clsx("block-image-wrapper", className)}>
      <Typography.Text>{label}</Typography.Text>
      <div
        className={clsx({
          "nucleus--dropzone": true,
          "dz-drag-active": isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} disabled={loading} />

        {uploadedFile ? (
          <div className="image-wrapper">
            <img src={uploadedFile.url} className="image-preview" />
          </div>
        ) : (
          <>
            <div className="block-image-wrapper">
              <Button type="primary" size="small" icon="image">
                Upload
              </Button>
            </div>
          </>
        )}
      </div>
      {meta && meta.invalid && meta.submitFailed && "This field is required."}
      {loading && <Progress active />}
    </div>
  );
};

export default FilesDropzone;
