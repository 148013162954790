import adminMenuItems from 'common/utils/Menu';

const menuLinks = [
  {
    group: 'Blog Articles',
    icon: 'copy',
    link: '/articles',
    items: []
  },
  {
    group: 'Site Users',
    icon: 'user',
    link: '/users',
    items: []
  },
  {
    group: 'Contact Requests',
    icon: 'mail',
    link: '/contact-requests',
    items: []
  },
  ...adminMenuItems
];

export default menuLinks;
