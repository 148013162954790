import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Page from 'components/Page';
import { useSelector } from 'react-redux';
import { useRouter } from 'common/hooks';

const PrivateRoute = ({ component: Component, title, auth, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const loading = useSelector(state => state.auth.loading);
  const permissions = useSelector(state => state.auth.permissions);

  const router = useRouter();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Page title={title} permissions={permissions} auth>
            <Component {...props} />
          </Page>
        ) : (
          !loading && (
            <Redirect
              to={{
                pathname: '/',
                state: router.location.pathname
              }}
            />
          )
        )
      }
    />
  );
};

export default PrivateRoute;
