import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';

const ButtonComponent = (props) => {
  const { to, mode, ...rest } = props;

  const setMode = () => {
    if (mode === 'success') {
      return 'nucleus--success-btn';
    }
  };

  if (to) {
    return (
      <Link to={props.to}>
        <Button {...rest} className={setMode()}>
          {props.children}
        </Button>
      </Link>
    );
  }
  return (
    <Button className={setMode()} {...rest}>
      {props.children}
    </Button>
  );
};

export default ButtonComponent;
