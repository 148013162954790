import React from 'react';
import { Tabs } from 'antd';
import { reduxForm, Form } from 'redux-form';
import { toast } from 'react-toastify';
import SEOFields from 'components/SEOFields';
import FormHeader from './components/FormHeader';
import './index.scss';

const { TabPane } = Tabs;

const FormWrapper = props => {
  const { setup, reset, displayTitle, tabs, handleSubmit, seo } = props;

  const [renderTabs, setRenderTabs] = React.useState([...tabs]);

  React.useEffect(() => {
    if (seo) {
      setRenderTabs([
        ...renderTabs,
        {
          label: 'SEO',
          component: <SEOFields />
        }
      ]);
    }
  }, []);

  React.useEffect(() => {
    if (props.submitFailed) {
      toast.error(
        `There are errors in this form.  Please check.
        `
      );
    }
  }, [props.submitFailed]);

  return (
    <div id="form-wrapper">
      <FormHeader setup={setup} reset={reset} displayTitle={displayTitle} />

      <Form onSubmit={handleSubmit}>
        <Tabs
          animated={false}
          type="card"
          tabBarStyle={{
            marginBottom: 0,
            zIndex: 5,
            borderBottom: 0
          }}
        >
          {renderTabs.map((panel, index) => (
            <TabPane tab={panel.label ? panel.label : 'Details'} key={index}>
              {panel.component}
            </TabPane>
          ))}
        </Tabs>
      </Form>
    </div>
  );
};

const EditForm = reduxForm({
  form: 'EditForm',
  enableReinitialize: true
})(FormWrapper);

export default EditForm;
