import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import DevTape from 'components/DevTape';
import RouteWrapper from 'components/RouteWrapper';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import './assets/theme/index.scss';

import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.min.css';

import { checkSite } from '@nucleus-store/global';
import { checkAuth } from '@nucleus-store/auth';

import { ConfigProvider } from 'antd';

import store from './store';

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const NucleusCore = () => {
  const testMode = process.env.REACT_APP_TEST_MODE === 'true' ? true : false;
  useEffect(() => {
    if (getUrlParameter('auth') !== 'false' || getUrlParameter('auth') === undefined) {
      store.dispatch(checkAuth());
    }
    store.dispatch(checkSite());
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider>
        {testMode && <DevTape />}
        <RouteWrapper />
      </ConfigProvider>
    </Provider>
  );
};

export default NucleusCore;
