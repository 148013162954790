import { createSlice } from 'redux-starter-kit';

import {
  commonReducers,
  List,
  Fetch,
  Create,
  Edit,
  Delete,
  Reset,
} from 'common/utils/CrudRedux';

const endpoint = '/sandbox';

const slice = createSlice({
  slice: 'sandbox',
  initialState: {
    list: [],
    entry: {},
    error: null,
    loading: false,
  },
  reducers: {
    ...commonReducers,
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = slice;
// Extract and export each action creator by name

export const fetchAll = () => List(actions, endpoint);
export const fetch = (id) => Fetch(id, actions, endpoint);
export const create = (values) => Create(actions, endpoint, values);
export const edit = (id, values) => Edit(actions, endpoint, id, values);
export const deleteEntries = (ids) => Delete(actions, endpoint, ids);
export const resetForm = () => Reset(actions);

// Export the reducer, either as a default or named export
export default reducer;
