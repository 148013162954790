import { toast } from 'react-toastify';
import Http from 'common/utils/Http';
import { loadingStart, loadingFinish, redirect } from '@nucleus-store/global';
import store from '@nucleus-store/index';
import config from 'config';

const setSite = localStorage.getItem('site');
let site;

if (setSite) {
  const parsed = JSON.parse(setSite);
  site = parsed.path;
} else {
  const setSite = config.siteSwitcher.sites[0];
  site = setSite.path;
  localStorage.setItem('site', JSON.stringify(setSite));
}

export const fetchAll = async ({
  endpoint,
  search = {
    search: '',
    sort: null,
    order: null,
    pagination: 15
  }
}) => {
  store.dispatch(loadingStart());
  try {
    const data = await Http.get(`${site}${endpoint}`, { params: search });
    store.dispatch(loadingFinish());
    return data.data;
  } catch (error) {
    toast.error('Error fetching entries');
    store.dispatch(loadingFinish());
    return [];
  }
};

export const fetchData = async ({ endpoint, id }) => {
  store.dispatch(loadingStart());
  try {
    const res = await Http.get(`${site}${endpoint}/${id}`);
    store.dispatch(loadingFinish());
    return res.data.data;
  } catch (error) {
    toast.error('Error fetching entry');
    store.dispatch(loadingFinish());
    return null;
  }
};

export const createFunction = ({ endpoint, values }) => {
  store.dispatch(loadingStart());
  Http.post(`${site}${endpoint}`, values)
    .then(res => {
      toast.success('Entry created successfully');
      store.dispatch(redirect());
      store.dispatch(loadingFinish());
      return res.data.data;
    })
    .catch(() => {
      toast.error('Error creating entry');
      store.dispatch(loadingFinish());
    });
};

export const editFunction = ({ endpoint, values, id }) => {
  store.dispatch(loadingStart());
  Http.put(`${site}${endpoint}/${id}`, values)
    .then(res => {
      toast.success('Entry saved successfully');
      store.dispatch(redirect());
      store.dispatch(loadingFinish());
      return res.data.data;
    })
    .catch(() => {
      toast.error('Error saving entry');
      store.dispatch(loadingFinish());
    });
};

export const deleteFunction = ({ endpoint, ids }) => {
  store.dispatch(loadingStart());
  Http.delete(`${site}${endpoint}`, { data: { id: ids } })
    .then(() => {
      store.dispatch(redirect());
      store.dispatch(loadingFinish());
      toast.success('Entry(s) deleted successfully');
      setTimeout(function() {
        window.location.reload();
      }, 800);
    })
    .catch(() => {
      toast.error('Could not delete entry(s)');
      store.dispatch(loadingFinish());
    });
};
