import React from 'react';
import { Form } from 'antd';
import Select from 'react-select';
import './index.scss';

const SelectField = (props) => (
  <Form.Item
    required={props.required}
    extra={props.extra}
    validateStatus={
      props.meta.invalid && props.meta.submitFailed ? 'error' : ''
    }
    help={
      props.meta.invalid && props.meta.submitFailed
        ? props.meta.error
        : props.help
    }
    hasFeedback={props.meta.invalid && props.meta.submitFailed}
    label={props.label}
    className="nucleus--select"
  >
    <Select
      showSearch
      {...props}
      isMulti={props.multiple}
      value={props.input.value}
      placeholder={props.placeholder}
      getOptionLabel={(option) => (props.labelKey ? option[props.labelKey] : option)}
      getOptionValue={(option) => (props.valueKey ? option[props.valueKey] : option)}
      options={props.options}
      onChange={(value) => props.input.onChange(value)}
      styles={{
        control: (base, state) => ({
          ...base,
          boxShadow: 'none',
          border: state.isFocused && '1px solid #3f51b5',
          '&:hover': {
            border: state.isFocused && '1px solid #3f51b5',
          },
        }),
      }}
    />
  </Form.Item>
);

export default SelectField;
