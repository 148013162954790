import React from 'react';
import CrudEntryWrapper from 'components/CrudHOC';
import ViewTitle from 'components/ViewTitle';
import ContentWrapper from 'components/ContentWrapper';

import Button from 'components/Button';
import { setup } from './setup';

const View = (props) => {
  const { data } = props;

  const displayTitle = `${data.id}`;

  // const permissions = useSelector(state => state.users.entry.permissions);

  return (
    <ContentWrapper fullWidth>
      <ViewTitle title={displayTitle} subtitle="Order">
        <Button
          type="primary"
          style={{ marginRight: 10 }}
          to={`/${setup.modelName}/edit/${data.id}`}
        >
          Edit Order
        </Button>
        <Button type="danger">Delete Order</Button>
      </ViewTitle>
      {data.id && <></>}
    </ContentWrapper>
  );
};

const EntryWrapperWithCrud = CrudEntryWrapper(View, setup);

export default EntryWrapperWithCrud;
