import React from 'react';
import { Field } from 'redux-form';
import { Card, Row, Col } from 'antd';
import * as validations from 'common/utils/Validations';

import TextInput from 'fields/TextField';
import Select from 'fields/Select';

const EditFormWrapper = () => (
  <Card title="Personal Information">
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Field
          component={TextInput}
          placeholder="Enter Category Name"
          label="Category Name"
          name="title"
          required
          validate={validations.required}
        />
      </Col>
    </Row>
  </Card>
);

export default EditFormWrapper;
