import React from 'react';
import TextField from 'fields/TextField';
import BlockWrapper from '../BlockWrapper';

/* eslint-disable */
const TextInput = props => {
  const {
    input,
    index,
    label,
    config,
    moveUp,
    moveDown,
    meta,
    remove,
    ...rest
  } = props;

  return (
    <BlockWrapper
      index={index}
      label={label}
      config={config}
      moveUp={moveUp}
      moveDown={moveDown}
      remove={remove}
    >
      <TextField {...props} input={input} meta={meta} />
    </BlockWrapper>
  );
};

export default TextInput;
