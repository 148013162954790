import React from 'react';
import { Checkbox, Form } from 'antd';

const CheckboxesGroup = (props) => {
  const {
    meta, required, input, label, help,
  } = props;

  const handleChange = (value) => {
    props.input.onChange(value);
  };

  return (
    <div>
      <Form.Item
        label={props.options && label}
        required={required}
        validateStatus={meta.invalid && meta.submitFailed ? 'error' : ''}
        help={meta.invalid && meta.submitFailed ? meta.error : help}
        hasFeedback={meta.invalid && meta.submitFailed}
      >
        {!props.options ? (
          <Checkbox
            checked={input.value}
            {...props}
            onChange={(e) => handleChange(e.target.checked)}
          >
            {label}
          </Checkbox>
        ) : (
          <Checkbox.Group
            options={props.options}
            value={input.value}
            {...props}
            onChange={handleChange}
          />
        )}
      </Form.Item>
    </div>
  );
};

export default CheckboxesGroup;
