import React from 'react';

import { useRouter } from 'common/hooks';

import { Menu, Icon } from 'antd';
import menuLinks from './Links';

import './index.scss';

const { SubMenu } = Menu;

export default function MainMenu() {
  const router = useRouter();

  const isInLink = href => {
    if (
      router.location.pathname === href ||
      `/${router.location.pathname.split('/')[1]}` === href
    ) {
      return true;
    }
  };

  const goToLink = item => {
    if (item.target === '_blank') {
      window.open(item.href, '_blank');
    } else {
      router.history.push(item.href);
    }
  };

  const NewWindow = name => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{name}</span>{' '}
      <Icon type="logout" style={{ fontSize: 17, marginLeft: 10, opacity: 0.5 }} />
    </div>
  );

  return (
    <>
      <Menu style={{ width: '100%' }} mode="inline" className="nucleus--side-menu">
        {menuLinks &&
          menuLinks.map((group, groupIndex) =>
            group.items && group.items.length === 0 ? (
              <Menu.Item
                key={`${group.group}-${groupIndex}`}
                className="group"
                onClick={() => router.history.push(group.link)}
              >
                <span>
                  <Icon type={group.icon} className="menu-icon" />
                  <span className="menu-title">{group.group}</span>
                </span>
              </Menu.Item>
            ) : (
              <SubMenu
                key={`${group.group}-${groupIndex}`}
                className="group"
                title={
                  <span>
                    <Icon type={group.icon} className="menu-icon" />
                    <span className="menu-title">{group.group}</span>
                  </span>
                }
              >
                {group.items &&
                  group.items.map((item, index) => (
                    <Menu.Item
                      key={`${item.title}-${index}-${groupIndex}`}
                      onClick={() => goToLink(item)}
                      className={isInLink(item.href) && 'link-active'}
                      style={{ height: 36, lineHeight: '32px', marginBottom: 0 }}
                    >
                      {item.target ? NewWindow(item.title) : item.title}
                    </Menu.Item>
                  ))}
              </SubMenu>
            )
          )}
      </Menu>
    </>
  );
}
