import React from 'react';
import RichEditor from 'fields/RichEditor';
import BlockWrapper from '../BlockWrapper';

/* eslint-disable */
const TextInput = props => {
  const { input, index, label, config, moveUp, moveDown, remove, meta } = props;

  return (
    <BlockWrapper
      index={index}
      label={label}
      config={config}
      moveUp={moveUp}
      moveDown={moveDown}
      remove={remove}
    >
      <RichEditor input={input} meta={meta} />
    </BlockWrapper>
  );
};

export default TextInput;
