function createCrud(name, component, list, view = null) {
  const url = name.replace(/\s+/g, '-').toLowerCase();
  const array = [
    {
      path: `/${url}/:type(create)`,
      title: `${name} - Create`,
      exact: true,
      auth: true,
      component,
    },
    {
      path: `/${url}/:type(create|edit)/:id`,
      title: `${name} - Edit`,
      exact: true,
      auth: true,
      component,
    },
    {
      path: `/${url}`,
      title: `${name} - View All`,
      exact: true,
      auth: true,
      component: list,
    },
  ];

  if (view !== null) {
    array.splice(1, 0, {
      path: `/${url}/:type(view)/:id`,
      title: `${name} - View`,
      exact: true,
      auth: true,
      component: view,
    });
  }

  return array;
}

export default createCrud;
