import React from 'react';

import ViewTitle from 'components/ViewTitle';
import Button from 'components/Button';
import DataList from 'components/ListTable';
import ContentWrapper from 'components/ContentWrapper';
import { setup } from './setup';
import columns from './ListColumns';

const config = {
  columns,
  setup,
  Component: null,
  testData: null,
  permissions: [],
};

const CreateButton = () => (
  <Button type="primary" mode="success" to={`/${setup.model}/create`}>
    Add New
    {' '}
    {setup.singular}
  </Button>
);

const List = (props) => {
  const { children } = props;

  return (
    <ContentWrapper fullWidth>
      <ViewTitle title={setup.title} subtitle="List">
        {setup.canAddNew && <CreateButton />}
      </ViewTitle>
      {children}
    </ContentWrapper>
  );
};

const ListWithTable = DataList(List, config);

export default ListWithTable;
