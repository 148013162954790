const modelName = 'Pages';
const modelNameSingular = 'Page';
const endpoint = 'pages';

const setup = {
  endpoint,
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true
};

export { setup };
