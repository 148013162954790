import React from 'react';
import Button from 'components/Button';
import { Typography } from 'antd';
import { setup } from './setup';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
    render: (text, record) => <Typography.Text code>{text}</Typography.Text>
  },
  {
    title: 'Author',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Is Featured?',
    key: 'featured',
    dataIndex: 'featured',
    render: (text, record) => (text ? 'Yes' : 'No')
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Button type="primary" ghost size="small" to={`/${setup.model}/edit/${record.id}`}>
        Edit
      </Button>
    )
  }
];

export default columns;
