import React from 'react';

import { Input } from 'antd';

const { Search } = Input;

const SearchComponent = (props) => {
  const {
    onSearch,
    className,
    handleSearch,
    submitSearch,
    value,
    ...rest
  } = props;

  return (
    <div {...rest} className={className} style={{ maxWidth: 400 }}>
      <Search
        placeholder="Search Results"
        onSearch={(value) => handleSearch(value)}
        loading={props.loading}
        allowClear
      />
    </div>
  );
};

export default SearchComponent;
