import React from 'react';
import './index.scss';

const ContentWrapper = (props) => {
  const { children } = props;

  return (
    <div
      className="nucleus--content-wrapper"
      id="content-wrapper"
      style={{ maxWidth: props.fullWidth ? 'none' : '1300px' }}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
