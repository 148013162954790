import React, { useEffect } from 'react';
import { Field } from 'redux-form';

import { Card, Row, Col } from 'antd';
import * as validations from 'common/utils/Validations';

import TextInput from 'fields/TextField';

const EditFormWrapper = () => {
  return (
    <>
      <Card title="Personal Information">
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="First Name"
              label="First Name"
              name="first_name"
              required
              validate={validations.required}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="Last Name"
              label="Last Name"
              name="last_name"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="Email Address"
              label="Email Address"
              name="email"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="Password"
              label="Password"
              name="password"
              required
              validate={validations.required}
            />
          </Col>{' '}
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="Confirm Password"
              label="Confirm Password"
              name="password_confirmation"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EditFormWrapper;
