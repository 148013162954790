import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import Http from 'common/utils/Http';

const Wysiwyg = props => {
  const { input, label, meta, placeholder, help, inline, style } = props;
  const globalLoading = useSelector(state => state.global.loading);
  const [loading, setLoading] = useState(true);
  const editor = React.createRef(null);

  const [state, setState] = useState(null);

  const uploadFiles = async files => {
    const form = new FormData();
    form.append('file', files[0]);
    const test = await Http.post('/file-upload/image', form).then(res => {
      return {
        file: {
          url: res.data.url
        }
      };
    });
    return test;
  };

  useEffect(() => {
    if (!globalLoading) {
      setLoading(globalLoading);
    }
  }, [globalLoading]);

  /* eslint-disable */
  useEffect(() => {
    setState(input.value ? input.value : '');
    $R(editor.current, {
      plugins: inline ? [] : ['table', 'alignment', 'fontcolor'],
      toolbarFixedTopOffset: 140,
      linkNewTab: true,
      buttons: [
        'redo',
        'undo',
        'format',
        'underline',
        'bold',
        'italic',
        'ul',
        'link',
        'image',
        'fontcolor'
      ],
      linkTitle: true,
      imageUpload: function(data, files, e, upload) {
        return uploadFiles(files).then(function(response) {
          upload.complete(response);
        });
      },
      air: inline ? true : false,
      callbacks: {
        blur: function() {
          props.input.onChange(this.api('module.source.getCode'));
        }
      }
    });
  }, [input.value, loading]);

  return (
    <div className={meta.error && meta.touched ? 'error' : ''}>
      <h4 style={{ marginBottom: 10 }}>{label}</h4>

      <Form.Item extra={help}>
        {!inline ? (
          <div
            data-redactor
            className="nucleus--content"
            name={input.name}
            style={{
              border: '1px solid rgba(0,0,0,0.15)',
              padding: 7,
              borderRadius: '3px',
              lineHeight: '26px'
            }}
            placeholder={placeholder}
            ref={editor}
            dangerouslySetInnerHTML={{ __html: state ? state : 'Start typing' }}
          ></div>
        ) : (
          <div
            className="content"
            name={input.name}
            ref={editor}
            style={{
              color: style.color,
              '& *': { color: style.color },
              fontFamily: style.fontFamily,
              ...style
            }}
          >
            {state ? state : placeholder}
          </div>
        )}
      </Form.Item>

      {meta && meta.invalid && meta.submitFailed && (
        <p variant="caption" color="error">
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default Wysiwyg;
