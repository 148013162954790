import React from 'react';
import CrudEntryWrapper from 'components/CrudHOC';
import ViewTitle from 'components/ViewTitle';
import ContentWrapper from 'components/ContentWrapper';
import { Card } from 'antd';
import moment from 'moment';
import Button from 'components/Button';
import { setup } from './setup';

const View = props => {
  const { data } = props;

  const displayTitle = `${data.name}`;

  // const permissions = useSelector(state => state.users.entry.permissions);

  return (
    <ContentWrapper fullWidth>
      <ViewTitle title={displayTitle} subtitle="Contact Request">
        <Button type="primary" style={{ marginRight: 10 }} to={`/${setup.model}`}>
          Back
        </Button>
      </ViewTitle>
      {data.id && (
        <>
          <Card title={`Sent ${moment(data.created_at).format('DD/MM/YYYY HH:mm')}`}>
            {data.message}
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};

const EntryWrapperWithCrud = CrudEntryWrapper(View, setup);

export default EntryWrapperWithCrud;
