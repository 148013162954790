import React from 'react';

import { Switch, Form } from 'antd';

const Switches = (props) => {
  const {
    input, meta, required, label, help,
  } = props;

  const handleChange = (value) => {
    input.onChange(value);
  };

  return (
    <div>
      <Form.Item
        layout="horizontal"
        required={required}
        validateStatus={meta.invalid && meta.submitFailed ? 'error' : ''}
        help={meta.invalid && meta.submitFailed ? meta.error : help}
        hasFeedback={meta.invalid && meta.submitFailed}
      >
        <Switch
          checked={input.value}
          onChange={(checked) => handleChange(checked)}
          defaultChecked={input.value}
        />

        <span style={{ marginLeft: 10, fontWeight: 500 }}>{label}</span>
      </Form.Item>
    </div>
  );
};

export default Switches;
