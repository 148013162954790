import React from 'react';
import { useRouter } from 'common/hooks';
import ViewTitle from 'components/ViewTitle';
import { Dropdown, Menu } from 'antd';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import Sticky from 'react-stickynode';
import { Link as RouterLink } from 'react-router-dom';

import './FormHeader.scss';

const CrudActionHeader = props => {
  const { setup, reset, displayTitle } = props;
  const loading = useSelector(state => state.global.loading);
  const router = useRouter();
  const dispatch = useDispatch();

  const displayTitleToShow =
    router.match.params.type === 'edit' ? displayTitle : `Create ${setup.singular}`;

  const subTitleToShow = router.match.params.type === 'edit' ? `Edit ${setup.singular}` : null;

  const resetValues = () => {
    const confirm = window.confirm(
      'Are you sure you want to clear this form?  All values will be lost.'
    );
    if (confirm === true) {
      dispatch(reset());
    } else {
      return false;
    }
  };

  const deleteEntries = () => {
    const confirm = window.confirm(
      `Are you sure you want to delete this ${setup.singular}?  This action can not be undone.`
    );
    if (confirm === true) {
      dispatch(setup.deleteFunction([router.match.params.id]));
    } else {
      return false;
    }
  };

  const menu = (
    <Menu>
      {setup.canAddNew && (
        <Menu.Item key="1">
          <RouterLink to={`/${setup.model}/create`}>{`Add New ${setup.singular}`}</RouterLink>
        </Menu.Item>
      )}
      <Menu.Item key="2">
        <RouterLink to={`/${setup.model}`}>{`View All ${setup.model}`}</RouterLink>
      </Menu.Item>
      {setup.canDelete && (
        <Menu.Item key="3" onClick={() => deleteEntries()}>
          {`Delete ${setup.singular}`}
        </Menu.Item>
      )}
      {router.match.params.type === 'create' && (
        <Menu.Item key={4} onClick={() => resetValues()} dense>
          Clear Form
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Sticky innerZ={500}>
      <div className="bar">
        <div className="bar-content">
          <ViewTitle title={displayTitleToShow} subtitle={subTitleToShow}>
            <div>
              <Dropdown overlay={menu} disabled={loading} loading={loading}>
                <Button icon="down">More Actions</Button>
              </Dropdown>
            </div>
            <div>
              <Button
                type="primary"
                mode="success"
                onClick={() => dispatch(submit('EditForm'))}
                style={{ marginLeft: 10 }}
                disabled={loading}
                loading={loading}
                className="nucleus--success-btn"
              >
                {`Save ${setup.singular}`}
              </Button>
            </div>
          </ViewTitle>
        </div>
      </div>
    </Sticky>
  );
};

export default CrudActionHeader;
