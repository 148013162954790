import Home from '../containers/Dashboard';
import Articles from '../containers/Blog/routes';
import Pages from '../containers/Pages/routes';
import AdminUsers from '../containers/AdminUsers/routes';
import Users from '../containers/Users/routes';
import Categories from '../containers/Categories/routes';
import ContactRequests from '../containers/ContactRequests/routes';

const routes = [
  {
    path: '/cms',
    title: 'Dashboard',
    exact: true,
    auth: true,
    component: Home
  },
  ...Pages,
  ...Articles,
  ...AdminUsers,
  ...Users,
  ...Categories,
  ...ContactRequests
];

export default routes;
