import { configureStore } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import globalReducer from './global';
import clientReducers from '../../Client/store';

import sandbox from '../containers/Sandbox/redux';

const rootReducer = combineReducers({
  auth,
  global: globalReducer,
  form: formReducer,
  ...clientReducers,
  sandboxes: sandbox,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
