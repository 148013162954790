const modelName = 'Articles';
const modelNameSingular = 'Article';
const endpoint = 'blogs';

const setup = {
  endpoint,
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true
};

export { setup };
