import React from 'react';

import { Row, Col, Typography } from 'antd';
import './index.scss';

const { Title } = Typography;

const ViewTitle = props => {
  const { title, children, subtitle } = props;

  return (
    <div className="nucleus--view-title">
      <Row gutter={16} type="flex">
        <Col className="title-container" xs={24} md={12} style={{ flexGrow: 1 }}>
          <h4 className="subtitle" style={{ marginBottom: 0 }}>
            {subtitle || ''}
          </h4>
          <Title level={2} className="nucleusTitle">
            {title}
          </Title>
        </Col>
        <Col className="button-container" xs={24} md={12}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default ViewTitle;
