import React from 'react';
import { FieldArray } from 'redux-form';

import renderBlocks from './components/RenderBlocks';

const Blocks = (props) => {
  const { config } = props;

  return <FieldArray config={config} name="blocks" component={renderBlocks} />;
};

export default Blocks;
