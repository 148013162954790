import React from 'react';
import { Field } from 'redux-form';
import { Card, Row, Col } from 'antd';
import * as validations from 'common/utils/Validations';

import RichEditor from 'fields/RichEditor';

const PageForm = () => (
  <Card title="Page Content">
    <Row gutter={16}>
      <Col xs={24}>
        <Field
          component={RichEditor}
          placeholder="Enter text"
          label="Top Content"
          name="top_content"
          help="Enter the content for the top section"
          required
          validate={validations.required}
        />
      </Col>
    </Row>
  </Card>
);

export default PageForm;
