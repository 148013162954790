const modelName = 'Blog Categories';
const modelNameSingular = 'Category';
const endpoint = 'categories';

const setup = {
  endpoint,
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true
};

export { setup };
