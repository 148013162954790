import Login from '../containers/Login';
import NotFound from '../containers/NotFound';
import clientRoutes from '../../Client/routes';
import sandboxRoutes from '../containers/Sandbox/routes';

const routes = [
  ...clientRoutes,
  ...sandboxRoutes,
  {
    path: '/',
    title: 'Login',
    exact: true,
    auth: false,
    component: Login
  },
  {
    component: NotFound
  }
];

export default routes;
