import React from 'react';

import { Drawer, Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenuState } from '@nucleus-store/global';

import NucleusLogo from '../../assets/images/nucleus-logo-2.svg';
import ClientMenu from '../../../Client/components/MainMenu';
import './index.scss';

const { Sider } = Layout;

export default function MainMenu(props) {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const open = useSelector(state => state.global.menuOpen);

  if (!isMobile) {
    return (
      <Sider
        width={240}
        trigger={null}
        collapsed={false}
        style={{
          overflow: 'auto',
          height: 'auto',
          background: 'white',
          position: 'relative',
          left: 0
        }}
      >
        <div className="nucleus--main-menu">
          <div className="menu-inner">
            <ClientMenu />
          </div>

          <div className="menu-bottom">
            <div className="logo-wrapper">
              <img src={NucleusLogo} width={140} alt="Pixel Logo" />
            </div>
          </div>
        </div>
      </Sider>
    );
  }
  if (isMobile) {
    return (
      <Drawer
        visible={open}
        width={240}
        bodyStyle={{ padding: 0 }}
        zIndex={0}
        placement="left"
        maskClosable
        onClose={() => dispatch(toggleMenuState())}
      >
        <>
          <div className="nucleus--main-menu">
            <div className="menu-inner">
              <ClientMenu />
            </div>

            <div className="menu-bottom">
              <div className="menu-logo-wrapper">
                <img src={NucleusLogo} width={140} alt="Pixel Logo" />
              </div>
            </div>
          </div>
        </>
      </Drawer>
    );
  }
}
