import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenuState } from '@nucleus-store/global';

import Hidden from '@material-ui/core/Hidden';
import config from 'config';
import { logout } from '@nucleus-store/auth';
import SiteSwitcher from 'components/SiteSwitcher';
import { Spin, Icon, Button } from 'antd';

import './index.scss';

const spinIcon = <Icon type="loading" style={{ fontSize: 25, color: 'white' }} spin />;

const HeaderBar = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.global.loading);
  const user = useSelector(state => state.auth.user);
  const site = useSelector(state => state.global.site);

  return (
    <div className="nucleus--header">
      <div className="header-block">
        <img src={site.clientLogoUrl} height={30} alt="Client Logo" />
        {config.siteSwitcher.show && <SiteSwitcher />}
      </div>

      <div className="header-block">
        <div className="progress">{isLoading && <Spin indicator={spinIcon} />}</div>
      </div>

      <Hidden mdUp implementation="js">
        <div className="header-block" style={{ justifyContent: 'flex-end' }}>
          <Button
            className="mobile-menu-button"
            icon="menu"
            ghost
            onClick={() => dispatch(toggleMenuState())}
          />
        </div>
      </Hidden>

      {user && (
        <Hidden smDown implementation="js">
          <div className="header-block" style={{ justifyContent: 'flex-end' }}>
            <div className="account-container">
              <Icon type="user" className="avatar" />
              <div className="user-container">
                <div className="username">{`Hello ${user.first_name}`}</div>
              </div>
            </div>

            <Button icons="logout" type="link" ghost onClick={() => dispatch(logout())}>
              Logout
            </Button>
          </div>
        </Hidden>
      )}
    </div>
  );
};

export default HeaderBar;
