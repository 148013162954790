import React from 'react';

import { Card, Icon, Divider, Typography } from 'antd';
import config from 'config';
import { useSelector } from 'react-redux';
import SignInForm from './components/SignInForm';
import NucleusLogo from '../../assets/images/nucleus-logo-2.svg';
import LoginImage from '../../assets/images/login.png';
import './index.scss';

const SignIn = props => {
  const { location, history } = props;
  const authIsLoading = useSelector(state => state.auth.loading);
  const site = useSelector(state => state.global.site);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (isAuthenticated) {
    history.push(site.loginRedirect);
  }

  return (
    <div className="nucleus--login">
      <div className="login-side">
        <Card className="nucleus--login-card">
          <Icon type="lock" className="icon" />

          <img src={site.clientLogoUrl} className="logo" alt="Nucleus Logo" />
          <Typography.Title level={3} className="nucleusTitle">
            Sign in
          </Typography.Title>
          <SignInForm location={location} loading={authIsLoading} />

          <Divider />

          <div className="link">
            <img src={NucleusLogo} width={170} alt="Pixel Logo" style={{ marginRight: 10 }} />
            <span>Built for {config.client}</span>
          </div>
        </Card>
      </div>
      <div
        className="image-side"
        style={{ backgroundImage: config.loginImage ? `url(${config.loginImage})` : null }}
      >
        {!config.loginImage && (
          <div>
            <img src={LoginImage} width={350} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
