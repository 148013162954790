import React from 'react';
import { Button, Popconfirm } from 'antd';

const DeleteButton = (props) => {
  const { selected, handleDelete } = props;
  return (
    <Popconfirm
      onConfirm={handleDelete}
      title={`Are you sure you want to delete ${selected.length} entries?`}
    >
      <Button icon="delete" type="link" disabled={selected.length === 0}>
        {`Delete ${selected.length} entries`}
      </Button>
    </Popconfirm>
  );
};

export default DeleteButton;
