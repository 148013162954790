import { loadingStart, loadingFinish, redirect } from '@nucleus-store/global';
import Http from 'common/utils/Http';
import { toast } from 'react-toastify';

const commonReducers = {
  error(state, action) {
    state.error = action.payload;
  },
  fetchSingle(state, action) {
    state.entry = action.payload;
  },
  fetchList(state, action) {
    state.list = action.payload;
  },
  createEntry(state, action) {
    state.entry = action.payload;
    state.success = true;
  },
  editEntry(state) {
    state.success = true;
  },
  deleteEntry(state) {
    state.entry = {};
  },
  clearEntry(state) {
    state.entry = {};
  },
  setFilter(state, action) {
    state.filters[action.payload.key] = action.payload.value;
  },
  clearFilters(state, action) {
    state.filters = action.payload;
  },
};

const List = (actions, endpoint, search) => (dispatch) => {
  dispatch(loadingStart());

  Http.get(endpoint, { params: search })
    .then((res) => {
      dispatch(actions.fetchList(res.data));
      dispatch(loadingFinish());
    })
    .catch((err) => {
      toast.error('Error fetching entries');
      dispatch(actions.error(err.message));
      dispatch(loadingFinish());
    });
};

const Fetch = (id, actions, endpoint) => (dispatch, getState) => {
  const { path } = getState().global.site;
  dispatch(loadingStart());

  Http.get(`${path}${endpoint}/${id}`)
    .then((res) => {
      dispatch(actions.fetchSingle(res.data.data));
      dispatch(loadingFinish());
    })
    .catch((err) => {
      toast.error('Error fetching entry');
      dispatch(actions.error(err.message));
      dispatch(loadingFinish());
    });
};

const Create = (actions, endpoint, values) => (dispatch, getState) => {
  const { path } = getState().global.site;
  dispatch(loadingStart());

  Http.post(`${path}${endpoint}`, values)
    .then((res) => {
      dispatch(actions.createEntry(res.data.data));
      toast.success('Entry created successfully');
      dispatch(redirect());
      dispatch(loadingFinish());
    })
    .catch((err) => {
      toast.error('Error creating entry');
      dispatch(actions.error(err.message));
      dispatch(loadingFinish());
    });
};

const Edit = (actions, endpoint, id, values) => (dispatch, getState) => {
  const { path } = getState().global.site;
  dispatch(loadingStart());

  Http.put(`${path}${endpoint}/${id}`, values)
    .then(() => {
      dispatch(actions.editEntry());
      toast.success('Entry saved successfully');
      dispatch(redirect());
      dispatch(loadingFinish());
    })
    .catch((err) => {
      toast.error(err.response.data.message);

      dispatch(actions.error(err.message));
      dispatch(loadingFinish());
    });
};

const Delete = (actions, endpoint, ids) => (dispatch, getState) => {
  const { path } = getState().global.site;
  dispatch(loadingStart());

  Http.delete(`${path}${endpoint}`, { data: { id: ids } })
    .then(() => {
      dispatch(actions.deleteEntry());
      dispatch(redirect());
      dispatch(loadingFinish());
      toast.success('Entry(s) deleted successfully');
    })
    .catch((err) => {
      toast.error('Could not delete entry(s)');
      dispatch(actions.error(err.response));
      dispatch(loadingFinish());
    });
};

const Reset = (actions) => (dispatch) => {
  dispatch(actions.clearEntry());
};

const Filter = (actions, key, value) => (dispatch) => {
  dispatch(actions.setFilter(key, value));
};

const ResetFilter = (actions, def) => (dispatch) => {
  dispatch(actions.clearFilters(def));
};

export {
  commonReducers,
  List,
  Fetch,
  Create,
  Edit,
  Delete,
  Reset,
  Filter,
  ResetFilter,
};
