import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray, change } from 'redux-form';
import { Row } from 'antd';
import BlockWrapper from '../BlockWrapper';
import columnInputs from './components/Columns';

/* eslint-disable */
const renderRow = ({ fields, changeRow, blocks }) => {
  const copyToAll = (value, index) => {
    const rowValues = fields.get(index);
    changeRow(rowValues);
  };

  return fields.map((field, index) => (
    <FieldArray
      name={`${field}.inputs`}
      key={`col-${index}`}
      component={columnInputs}
      index={index}
      copyToAll={copyToAll}
      blocks={blocks}
    />
  ));
};

const GridWrapper = props => {
  const { index, config, rows, fields, columns, label, moveUp, moveDown, remove, blocks } = props;

  /* eslint-disable */
  const [gridRows, setGridRows] = useState();
  const dispatch = useDispatch();
  const [gridColumns, setGridColumns] = useState();

  const times = x => f => {
    if (x > 0) {
      f();
      times(x - 1)(f);
    }
  };

  const makeRows = () => {
    if (fields.length !== 0) {
      console.log('value');
      //setRows(value);
    } else {
      let columnsArray = new Array(columns).fill().map((e, i) => {
        return {};
      });

      times(rows)(() => fields.push({ columns: columnsArray }));
    }
  };

  const changeRow = value => {
    fields.forEach((field, fieldIndex) => {
      const columns = fields.get(fieldIndex).columns;
      columns.forEach((column, columnIndex) => {
        dispatch(change('EditForm', `${field}.columns[${columnIndex}]`, value));
      });
    });
  };

  useEffect(() => {
    makeRows();
  }, []);

  return (
    <BlockWrapper
      index={index}
      length={length}
      label={label}
      config={config}
      moveUp={moveUp}
      moveDown={moveDown}
      remove={remove}
    >
      {fields.length !== 0 &&
        fields.map((row, index) => (
          <Row
            gutter={12}
            key={`row-${index}`}
            type="flex"
            justify="center"
            style={{ flexWrap: 'nowrap', flexFlow: 'nowrap' }}
          >
            <FieldArray
              name={`${row}.columns`}
              component={renderRow}
              changeRow={changeRow}
              blocks={blocks}
            />
          </Row>
        ))}
    </BlockWrapper>
  );
};

export default GridWrapper;
