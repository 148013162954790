import React from 'react';
import FileUpload from './components/FileUpload';
import BlockWrapper from '../BlockWrapper';

const Image = (props) => {
  const {
    input, index, label, config, moveUp, moveDown, remove,
  } = props;

  return (
    <BlockWrapper
      index={index}
      label={label}
      config={config}
      moveUp={moveUp}
      moveDown={moveDown}
      remove={remove}
    >
      <FileUpload input={input} />
    </BlockWrapper>
  );
};

export default Image;
