import React from 'react';
import { Button, Modal } from 'antd';
import config from 'config';
import { switchSite } from '@nucleus-store/global';
import { useDispatch } from 'react-redux';
import './index.scss';

const SiteSwitcher = () => {
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();

  const handleSwitchSite = (site) => {
    dispatch(switchSite(site));
    setVisible(false);
  };

  return (
    <>
      <Button
        icon="deployment-unit"
        ghost
        type="link"
        onClick={() => setVisible(true)}
        style={{ marginLeft: 10, fontWeight: 500 }}
      >
        Site Switcher
      </Button>
      <Modal
        title="Select Site"
        footer={null}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <div className="nucleus--siteswitcher">
          {config.siteSwitcher.sites.map((site, index) => (
            <div
              className="item"
              key={`site-${index}`}
              onClick={() => handleSwitchSite(site)}
            >
              <div className="name">{site.name}</div>
              <div className="image">
                <img src={site.clientLogoUrl} alt="site" />
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default SiteSwitcher;
