import createPageRoutes from 'common/utils/CreatePageRoutes';
import List from './List';
import About from './About';

import { setup } from './setup';

const pages = [
  {
    id: 1,
    component: About,
    title: 'About Us'
  }
];

const crud = createPageRoutes({ list: List, pages, setup });

const otherRoutes = [];

const routes = [...crud, ...otherRoutes];

export default routes;
