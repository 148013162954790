import React from 'react';
import Page from 'components/Page';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoutes = ({ component: Component, title, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Page title={title} auth={false}>
            <Component {...props} />
          </Page>
        </>
      )}
    />
  );
};

export default PublicRoutes;
