import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ViewTitle from 'components/ViewTitle';
import { Button } from 'antd';
import DataList from 'components/ListTable';
import ContentWrapper from 'components/ContentWrapper';

import { setup } from './setup';

const columns = [
  {
    Header: 'First Name',
    accessor: 'first_name'
  },
  {
    Header: 'Last Name',
    accessor: 'last_name'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Actions',
    Cell: props => {
      const { row } = props;
      return (
        <Button
          color="primary"
          size="small"
          component={RouterLink}
          to={`/${setup.model}/edit/${row.original.id}`}
          variant="outlined"
        >
          View
        </Button>
      );
    }
  }
];

const config = {
  columns,
  setup,
  Component: null,
  testData: null
};

const CreateButton = () => (
  <Button type="primary">
    <RouterLink to={`/${setup.model}/create`}>Add New {setup.singular}</RouterLink>
  </Button>
);

const List = props => {
  const { children } = props;

  return (
    <ContentWrapper fullWidth>
      <ViewTitle title={setup.title} subtitle="List">
        {setup.canAddNew && <CreateButton />}
      </ViewTitle>
      {children}
    </ContentWrapper>
  );
};

const ListWithTable = DataList(List, config);

export default ListWithTable;
