import React from 'react';
/* eslint-disable */

import { Input, Button, Card, Tooltip, Popconfirm } from 'antd';

import { Field } from 'redux-form';

import AddBlock from '../AddBlock';

import './index.scss';

const BlockWrapper = props => {
  const { index, children, length, label, config, moveUp, moveDown, remove } = props;

  const TextInput = props => {
    const { input } = props;

    return (
      <Input
        type="text"
        {...props}
        placeholder="Block Label"
        value={input.value}
        style={{ maxWidth: 250 }}
        onChange={e => input.onChange(e.target.value)}
      />
    );
  };

  /*eslint-disable */
  return (
    <div className="nucleus--block-wrapper">
      <Card
        hoverable
        title={config.mode === 'fluid' && <Field name={label} component={TextInput} />}
        actions={
          config.mode === 'fluid' && [
            <AddBlock blockIndex={index} config={config} type="link" />,
            <Tooltip title="Remove Block">
              <Popconfirm
                title="Are you sure you want to delete this block?"
                onConfirm={() => remove(index)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" icon="delete" />
              </Popconfirm>
            </Tooltip>,

            <>
              <Tooltip title="Move Up">
                <Button
                  type="link"
                  icon="up"
                  onClick={() => moveUp(index)}
                  disabled={index === 0}
                />
              </Tooltip>
              <Tooltip title="Move Down">
                <Button
                  type="link"
                  icon="down"
                  disabled={index + 1 === length}
                  onClick={() => moveDown(index)}
                />
              </Tooltip>
            </>
          ]
        }
      >
        {children}
      </Card>
    </div>
  );
};

export default BlockWrapper;
