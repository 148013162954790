import React from 'react';

import { Field, reduxForm, Form } from 'redux-form';
import * as validations from 'common/utils/Validations';

import { Card, Row, Col } from 'antd';

import TextInput from 'fields/TextField';
import FileUpload from 'fields/FileUpload';
import RichEditor from 'fields/RichEditor';
import Checkbox from 'fields/Checkbox';
import Switch from 'fields/Switch';
import Select from 'fields/Select';
import DatePicker from 'fields/DatePickers';
import BlockEditor from 'fields/BlockEditor';

const config = {
  mode: 'fixed', // fixed or fluid
  blocks: [
    { type: 'text', name: 'Text Field', icon: 'plus' },
    { type: 'richText', name: 'Rich Text', icon: 'text' },
    { type: 'image', name: 'Image Block', icon: 'image' },
    {
      type: 'grid',
      name: 'Grid Block (6x2)', // Name appropriately
      icon: 'grid',
      columns: 6, // Number of columns in a grid
      rows: 2, // Number of rows in a grid
      blocks: ['text', 'image', 'multiline'] // Available blocks to add within the grid columns.
    },
    {
      type: 'grid',
      name: 'Grid Block (3x1)',
      icon: 'grid',
      columns: 3,
      rows: 1,
      blocks: ['text', 'image', 'multiline']
    }
  ]
};

const EditFormWrapper = props => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Card title="Text Input">
        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <Field
              component={TextInput}
              placeholder="First Name"
              label="First Name"
              name="name"
              variant="outlined"
              required
              validate={validations.required}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              component={Select}
              options={[
                { id: 1, name: 'Matt' },
                { id: 2, name: 'Alex' }
              ]}
              multiple
              valueKey="id"
              labelKey="name"
              placeholder="Select Placeholder"
              label="Select Label"
              name="select"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>

      <Card title="Selector Inputs">
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Field
              component={Checkbox}
              label="House or Apartment?"
              name="house"
              required
              validate={validations.required}
            />
          </Col>
          <Col xs={24} sm={8}>
            <Field
              component={Switch}
              label="Testing Switch"
              name="switch"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Date Pickers">
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Field
              component={DatePicker}
              label="Testing DatePicker"
              name="date"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Upload Files">
        <Row gutter={16}>
          <Col>
            <Field
              component={FileUpload}
              name="images"
              accept=""
              required
              type="file"
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Banner Images">
        <Row gutter={16}>
          <Col>
            <Field
              component={RichEditor}
              label="Label for input"
              placeholder="Placeholder here"
              name="richEditor"
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Block Editor">
        <Row gutter={16}>
          <Col>
            <Field
              component={BlockEditor}
              label="Label for input"
              placeholder="Placeholder here"
              name="blocks"
              config={config}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

const EditForm = reduxForm({
  form: 'EditForm'
})(EditFormWrapper);

export default EditForm;
