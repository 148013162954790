/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'common/hooks';
import ScrollToTop from 'components/ScrollToTop';

const Page = props => {
  const { title, children } = props;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && router.location.pathname === '/') {
      router.history.push(router.location.state);
    }
  }, [dispatch, isAuthenticated, router.history, router.location.pathname, router.location.state]);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {children}
    </>
  );
};

export default Page;
