const modelName = 'Sandboxes';
const modelNameSingular = 'Sandbox';
const endpoint = '/sandboxes';

const setup = {
  endpoint,
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true
};

export { setup };
