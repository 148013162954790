import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Button } from 'antd';
import { login } from '@nucleus-store/auth';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const SignInForm = props => {
  const { className, location, ...rest } = props;

  const dispatch = useDispatch();

  const authIsLoading = useSelector(state => state.auth.loading);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(fState => ({
      ...fState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(fState => ({
      ...fState,
      values: {
        ...fState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...fState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    dispatch(login(formState.values));
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  return (
    <form className={className} onSubmit={handleSubmit} autoComplete="off">
      <div>
        <Form.Item
          validateStatus={hasError('email') ? 'error' : null}
          help={hasError('email') ? formState.errors.email[0] : null}
        >
          <Input
            size="large"
            style={{ width: '100%', minWidth: 270 }}
            placeholder="Email Address"
            autoFocus
            type="email"
            label="Email address"
            name="email"
            onChange={handleChange}
            value={formState.values.email || ''}
          />
        </Form.Item>
        <Form.Item
          validateStatus={hasError('password') ? 'error' : null}
          help={hasError('password') ? formState.errors.password[0] : null}
        >
          <Input
            size="large"
            style={{ width: '100%', minWidth: 270 }}
            placeholder="Password"
            label="Password"
            name="password"
            autoComplete="new-password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ''}
          />
        </Form.Item>
      </div>

      <Button
        type="primary"
        htmlType="submit"
        loading={props.loading}
        disabled={!formState.isValid || authIsLoading}
        size="large"
        block
        style={{ fontWeight: 500, fontSize: 20, height: 50 }}
      >
        Secure Login
      </Button>
    </form>
  );
};

export default SignInForm;
