import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Menu, Dropdown } from 'antd';

import { arrayPush, arrayInsert } from 'redux-form';

const AddBlock = (props) => {
  const { blockIndex, config, type } = props;

  const dispatch = useDispatch();

  const addBlock = (type, columns = null, rows = null, blocks) => {
    if (type === 'richText') {
      const object = {
        type: 'richText',
        value: '',
        label: '',
      };
      if (blockIndex !== undefined) {
        dispatch(arrayInsert('EditForm', 'blocks', blockIndex + 1, object));
      } else {
        dispatch(arrayPush('EditForm', 'blocks', object));
      }
    }
    if (type === 'text') {
      const object = {
        type: 'text',
        value: '',
        label: '',
      };
      if (blockIndex !== undefined) {
        dispatch(arrayInsert('EditForm', 'blocks', blockIndex + 1, object));
      } else {
        dispatch(arrayPush('EditForm', 'blocks', object));
      }
    }
    if (type === 'image') {
      const object = {
        type: 'image',
        value: '',
        label: '',
      };
      if (blockIndex !== undefined) {
        dispatch(arrayInsert('EditForm', 'blocks', blockIndex + 1, object));
      } else {
        dispatch(arrayPush('EditForm', 'blocks', object));
      }
    }
    if (type === 'grid') {
      const object = {
        type: 'grid',
        columns,
        rows,
        blocks,
        value: [],
        label: '',
      };
      if (blockIndex !== undefined) {
        dispatch(arrayInsert('EditForm', 'blocks', blockIndex + 1, object));
      } else {
        dispatch(arrayPush('EditForm', 'blocks', object));
      }
    }
  };

  const menu = (
    <Menu>
      {config.blocks.map((option, index) => (
        <Menu.Item
          key={index}
          onClick={() => addBlock(option.type, option.columns, option.rows, option.blocks)}
        >
          {option.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <Button type={type || 'primary'} icon="plus">
          Add New Block
        </Button>
      </Dropdown>
    </>
  );
};

export default AddBlock;
