import React from 'react';
import Button from 'components/Button';
import { setup } from './setup';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'surname',
    key: 'surname'
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Button type="primary" ghost size="small" to={`/${setup.model}/view/${record.id}`}>
        View
      </Button>
    )
  }
];

export default columns;
