import createCrud from 'common/utils/CreateCrud';
import List from './List';
import Entry from '.';
import View from './View';

import { setup } from './setup';

const crud = createCrud(setup.title, Entry, List, View);

const otherRoutes = [];

const routes = [...crud, ...otherRoutes];

export default routes;
