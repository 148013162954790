/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useRouter } from 'common/hooks';
import { useSelector } from 'react-redux';
import { Result, Button } from 'antd';
import { fetchData, createFunction, editFunction } from 'common/utils/CrudFunctions';

function EntryCrudWrapper(WrappedComponent, setup) {
  return function EntryWrapperWithCrud(props) {
    const router = useRouter();
    const [data, setData] = useState({});

    const [loading, setLoading] = useState(true);
    const isLoading = useSelector(state => state.global.loading);
    const hasRedirect = useSelector(state => state.global.redirect);
    const site = useSelector(state => state.global.site.path);

    const testMode = process.env.REACT_APP_TEST_MODE === 'true' ? true : false;

    useEffect(() => {
      if (isLoading !== loading) {
        setLoading(isLoading);
      }
    }, [isLoading]);

    useEffect(() => {
      const fetch = () => {
        fetchData({ site, endpoint: setup.endpoint, id: router.match.params.id }).then(data => {
          if (Array.isArray(data)) {
            setData(null);
          } else {
            setData(data);
          }
        });
      };

      if (router.match.params.type === 'create') {
        setData({});
      }

      if (router.match.params.type === 'edit' || router.match.params.type === 'view') {
        if (window.location.href.split('/')[3] !== 'pages' && !testMode) {
          fetch();
        }
      }
    }, []);

    const saveEntry = values => {
      if (router.match.params.type === 'edit') {
        editFunction({
          site,
          endpoint: setup.endpoint,
          values,
          id: router.match.params.id
        });
      }
      if (router.match.params.type === 'create') {
        createFunction({ site, endpoint: setup.endpoint, values });
      }
    };

    const resetForm = () => {
      setData({});
    };

    if (hasRedirect) {
      return <Redirect to={`/${setup.model}`} />;
    }

    return (
      <>
        {loading && (
          <div
            style={{
              width: '100%',
              minHeight: '100vh',
              position: 'fixed',
              background: '#f1f2f5',
              zIndex: 999
            }}
          ></div>
        )}
        <WrappedComponent
          {...props}
          saveEntry={saveEntry}
          data={data}
          resetForm={resetForm}
          loading={loading}
        />

        {!loading && !data && (
          <div className="nucleus--not-found">
            <Result
              status="500"
              title="No entry found"
              subTitle="Sorry, no data was returned from the database."
              extra={
                <Button type="primary" onClick={() => router.history.push(`/${setup.model}`)}>
                  Go Back
                </Button>
              }
            />
          </div>
        )}
      </>
    );
  };
}

export default EntryCrudWrapper;
