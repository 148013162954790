import React from 'react';
import { Field } from 'redux-form';

import { Card, Button, Dropdown, Menu, Tooltip, Popconfirm } from 'antd';
import TextField from 'fields/TextField';

import FileUpload from './FileUpload';
import AddField from './AddField';

import './Columns.scss';

const TooltipRender = props => {
  const { removeField, index, fields, children } = props;

  return (
    <Tooltip
      placement="right"
      title={
        <Button.Group>
          <Popconfirm
            title="Are you sure you want to delete this field?"
            onConfirm={() => removeField(index)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon="delete" ghost type="link"></Button>
          </Popconfirm>
          {index !== 0 && (
            <Button
              icon="up"
              ghost
              type="link"
              onClick={() => fields.move(index, index - 1)}
            ></Button>
          )}
          {index + 1 !== fields.length && (
            <Button
              icon="down"
              ghost
              type="link"
              onClick={() => fields.move(index, index + 1)}
            ></Button>
          )}
        </Button.Group>
      }
    >
      {children}
    </Tooltip>
  );
};

/* eslint-disable */
const columnInputs = props => {
  const { fields, index, copyToAll, blocks } = props;

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClickOpen() {
    setOpen(true);
    setAnchorEl(null);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpenMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const menuOpen = Boolean(anchorEl);

  const addField = object => {
    fields.push(object);
    setOpen(false);
  };

  const removeField = index => {
    fields.remove(index);
  };

  const IconComponent = props => (
    <Button style={{ marginLeft: 'auto' }} onClick={() => removeField(props.index)}></Button>
  );

  const menu = (
    <Menu>
      <Menu.Item onClick={handleClickOpen}>Add New Field</Menu.Item>

      <Menu.Item>
        <Popconfirm
          title="This will overwrite any data currently in those columns."
          onConfirm={() => copyToAll(fields.getAll(), index)}
          okText="OK"
          cancelText="Cancel"
        >
          <span>Copy to all</span>{' '}
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="nucleus--block-row">
        <AddField open={open} handleClose={handleClose} addField={addField} blocks={blocks} />
        <Card
          className="card"
          size="small"
          headStyle={{
            background: '#eef0f1',
            borderBottom: '1px solid rgba(0,0,0,0.15)'
          }}
          extra={
            <Dropdown overlay={menu}>
              <Button type="link" icon="setting"></Button>
            </Dropdown>
          }
        >
          {fields.length === 0 ? (
            <div className="empty-card">Block is empty. Add some fields.</div>
          ) : (
            fields.map((field, index) => {
              switch (fields.get(index).type) {
                case 'text':
                  return (
                    <TooltipRender
                      index={index}
                      fields={fields}
                      removeField={removeField}
                      key={`field-${index}`}
                    >
                      <div className="field-row">
                        <Field
                          component={TextField}
                          name={`${field}.value`}
                          size="small"
                          placeholder={fields.get(index).label}
                        />
                      </div>
                    </TooltipRender>
                  );
                  break;
                case 'image':
                  return (
                    <TooltipRender
                      index={index}
                      fields={fields}
                      removeField={removeField}
                      key={`field-${index}`}
                    >
                      <div className="field-row">
                        <Field
                          component={FileUpload}
                          label={fields.get(index).label}
                          name={`${field}.value`}
                          placeholder="Image"
                          key={`field=${index}`}
                        />
                      </div>
                    </TooltipRender>
                  );
                  break;
                case 'multiline':
                  return (
                    <TooltipRender
                      index={index}
                      fields={fields}
                      removeField={removeField}
                      key={`field-${index}`}
                    >
                      <div className="field-row">
                        <Field
                          component={TextField}
                          type="textarea"
                          rows="2"
                          name={`${field}.value`}
                          placeholder={fields.get(index).label}
                          key={`field=${index}`}
                          size="small"
                        />
                      </div>
                    </TooltipRender>
                  );
                  break;
              }
            })
          )}
        </Card>
      </div>
    </>
  );
};

export default columnInputs;
