import React, { useEffect, useState } from 'react';

import { Card, Pagination, Table } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useSelector } from 'react-redux';
import { fetchAll, deleteFunction } from 'common/utils/CrudFunctions';
import Search from './components/Search';
import DeleteButton from './components/DeleteButton';

import './index.scss';

function DataList(WrappedComponent, config) {
  return function DataListWithTable(props) {
    const { setup, Component, columns } = config;

    const loading = useSelector(state => state.global.loading);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [pagination, setPagination] = useState(15);
    const filters = useSelector(state => state.global.filters);

    /* eslint-disable */
    const [search, setSearch] = useState({
      search: '',
      sort: null,
      order: null,
      pagination: 15,
      page: 1
    });

    const fetchData = link => {
      fetchAll({
        endpoint: setup.endpoint,
        search: { ...search, filters: Object.keys(filters).length === 0 ? null : filters }
      }).then(res => {
        setDataList(res);
      });
    };

    // const permissions = useSelector((state) => state.auth.user.permissions);

    useEffect(() => {
      fetchData();
    }, [search, filters]);

    const handleChangePage = (page, pageSize) => {
      setSearch({ ...search, page: page });

      setPagination(pageSize);
    };

    const handleSort = column => {
      let sortOrder;
      let sortSort;
      if (!search.order) {
        sortOrder = 'asc';
        sortSort = column.id;
      }
      if (search.order === 'asc') {
        sortOrder = 'desc';
        sortSort = column.id;
      }
      if (search.order === 'desc') {
        sortOrder = null;
        sortSort = null;
      }
      setSearch({
        ...search,
        sort: sortSort,
        order: sortOrder
      });
      console.log(search);
    };

    const handleSearch = string => {
      if (setup.clientPagination) {
        let results = [];
        if (string !== '') {
          results = dataList.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(string.toLowerCase())
            )
          );
        } else {
          results = dataList;
        }
        setDataList(results);
      } else {
        setSearch({ ...search, search: string.toLowerCase() });
      }
    };

    const handleChangeRowsPerPage = (current, size) => {
      setSearch({ ...search, pagination: size });
    };

    const handleDelete = () => {
      deleteFunction({ endpoint: setup.endpoint, ids: selectedEntries });
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        var selected = [];
        selectedRows.forEach(function(obj) {
          selected.push(obj.id);
        });
        setSelectedEntries(selected);

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name
      })
    };

    const setSort = field => {
      if (search.order === 'asc') {
        setSearch({ ...search, order: 'desc', sort: field });
      }
      if (search.order === 'desc') {
        setSearch({ ...search, order: null, sort: field });
      }
      if (search.order === null) {
        setSearch({ ...search, order: 'asc', sort: field });
      }
    };

    const handleChange = (pagination, filters, sorter, extra) => {
      setPagination(pagination.current);
      setSort(sorter.field);
      console.log(search);
    };

    return (
      <WrappedComponent {...props}>
        <div>
          <p>
            {`${dataList.meta ? dataList.meta.total : dataList.data ? dataList.data.length : 0} ${
              setup.title
            } found.`}
          </p>

          <Card
            title={setup.canSearch && <Search handleSearch={handleSearch} value={search.search} />}
            extra={
              <div>
                {setup.canDelete && (
                  <DeleteButton selected={selectedEntries} handleDelete={handleDelete} />
                )}
              </div>
            }
            bodyStyle={{ padding: 0 }}
          >
            {Component !== null && (
              <div style={{ paddingTop: 5, paddingBottom: 5, paddingRight: 20 }}>
                <Component />
              </div>
            )}
            <PerfectScrollbar>
              <div>
                <Table
                  rowSelection={rowSelection}
                  size="small"
                  columns={columns}
                  dataSource={dataList.data}
                  loading={loading}
                  pagination={setup.clientPagination ? setup.clientPagination : false}
                  sortDirections={['asc', 'desc']}
                  onChange={handleChange}
                  rowKey="id"
                />
              </div>
            </PerfectScrollbar>
            {!setup.clientPagination && (
              <div style={{ padding: 10 }}>
                <Pagination
                  showSizeChanger
                  pageSize={search.pagination}
                  current={dataList.meta ? dataList.meta.current_page : pagination}
                  pageSizeOptions={[15, 30, 100]}
                  total={dataList.meta ? dataList.meta.total : dataList.length}
                  size="small"
                  onChange={handleChangePage}
                  onShowSizeChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          </Card>
        </div>
      </WrappedComponent>
    );
  };
}

export default DataList;
