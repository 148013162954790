import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Tooltip, Icon, Progress, Popconfirm } from 'antd';

const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const SortableWrapper = props => {
  const { onSortEnd, files } = props;

  return (
    <>
      <ReactSortable list={files} setList={onSortEnd}>
        {files &&
          Array.isArray(files) &&
          files.length !== 0 &&
          files.map((file, i) => (
            <div className="nucleus--sort-item" key={`file-${i}`}>
              <div className="si--thumb" onClick={() => props.openPreview(file.url)}>
                <img src={file.url} alt={file.url} />
              </div>

              <div className="si--text">
                <div className="si--file-name">{file.filename}</div>
                <div className="si--size">{file.size ? bytesToSize(file.size) : ''}</div>
              </div>

              {file.progress !== 0 ||
                (file.progress !== 100 && (
                  <div
                    style={{
                      width: 200,
                      marginLeft: 10,
                      marginRight: 10
                    }}
                  >
                    <Progress percent={file.progress} size="small" status="active" />
                  </div>
                ))}

              <Tooltip title="Remove Image">
                <Popconfirm
                  title="Are you sure you want to remove this file?"
                  onConfirm={() => props.removeImage(i)}
                >
                  <Icon type="delete" />
                </Popconfirm>
              </Tooltip>
            </div>
          ))}
      </ReactSortable>
    </>
  );
};

export default SortableWrapper;
