import React from 'react';
import { Field } from 'redux-form';
import { Card, Row, Col } from 'antd';
import Button from 'components/Button';
import * as validations from 'common/utils/Validations';
import { fetchCategories } from './functions';
import TextInput from 'fields/TextField';
import RichText from 'fields/RichEditor';
import Select from 'fields/Select';
import Switch from 'fields/Switch';
import FileUpload from 'fields/FileUpload';
import ContentEditor from 'fields/ContentEditor';

const EditFormWrapper = () => {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    fetchCategories().then(data => setCategories(data));
  }, []);

  return (
    <>
      <Card title="Article Information">
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Field
              component={TextInput}
              placeholder="Article Title"
              label="Article Title"
              name="title"
              required
              validate={validations.required}
            />
            <Field
              component={Select}
              options={categories}
              valueKey="id"
              labelKey="title"
              placeholder="Select categories"
              label="Categories"
              multiple
              name="categories"
              required
              validate={validations.required}
            />
            <Button type="link" to="/blog-categories/create" size="small" style={{ marginTop: 5 }}>
              Add new category
            </Button>
          </Col>
          <Col xs={24} sm={12} style={{ paddingTop: 25 }}>
            <Field component={Switch} label="Featured Article?" name="featured" />
            <Field component={Switch} label="Main Feature" name="main_featured" />
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Field
              component={TextInput}
              placeholder="Article Author"
              label="Article Author"
              name="owner"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Article Image">
        <Row gutter={20}>
          <Col xs={24}>
            <Field
              component={FileUpload}
              max={1}
              label="Article background image"
              name="image"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Article Content">
        <Row gutter={20}>
          <Col xs={24}>
            <Field
              component={TextInput}
              placeholder="Article Summary"
              label="Article Summary"
              name="summary"
              help="A short article summary of the article"
              required
              characterLimit={300}
              validate={validations.required}
            />
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Field
              component={RichText}
              label="Article Content"
              name="content"
              required
              validate={validations.required}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EditFormWrapper;
