import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ViewTitle from 'components/ViewTitle';
import { Button } from 'antd';
import DataList from 'components/ListTable';
import ContentWrapper from 'components/ContentWrapper';

import { setup } from './setup';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name'
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Button type="primary" ghost size="small" variant="outlined">
        <RouterLink to={`/${setup.model}/edit/${record.id}`}>View</RouterLink>
      </Button>
    )
  }
];

const config = {
  columns,
  setup,
  Component: null,
  testData: null
};

const CreateButton = () => (
  <Button type="primary">
    <RouterLink to={`/${setup.model}/create`}>Add New {setup.singular}</RouterLink>
  </Button>
);

const List = props => {
  const { children } = props;

  return (
    <ContentWrapper fullWidth>
      <ViewTitle title={setup.title} subtitle="List">
        {setup.canAddNew && <CreateButton />}
      </ViewTitle>
      {children}
    </ContentWrapper>
  );
};

const ListWithTable = DataList(List, config);

export default ListWithTable;
