import React, { useState } from 'react';

import underscored from 'underscore.string/underscored';

import {
  Modal, Button, Input, Form, Select,
} from 'antd';

const { Option } = Select;

const AddFieldModal = (props) => {
  const {
    open, handleClose, addField, blocks,
  } = props;

  const [object, setObject] = useState({
    label: null,
    name: null,
    type: null,
    value: null,
  });

  React.useEffect(() => {
    setObject({
      label: null,
      name: null,
      type: null,
      value: null,
    });
  }, []);

  return (
    <div>
      <Modal
        visible={open}
        onClose={handleClose}
        title="Select field type"
        footer={[
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>,
          <Button
            onClick={() => addField(object)}
            type="primary"
            disabled={object.type === null || object.label === null}
          >
            Add Field
          </Button>,
        ]}
      >
        <p>Enter a user friendly name for the input field.</p>

        <Input
          autoFocus
          placeholder="Enter field name."
          id="name"
          label="Field Name"
          type="text"
          fullWidth
          onChange={(e) => setObject({
            ...object,
            label: e.target.value,
            name: underscored(e.target.value),
          })}
        />

        <Form.Item required style={{ marginTop: 15 }}>
          <Select
            showArrow
            placeholder="Select type..."
            optionLabelProp="children"
            style={{ width: '100%' }}
            onChange={(value) => setObject({ ...object, type: value })}
          >
            {blocks.map((block, index) => (
              <Option key={`fieldType-${index}`} value={block}>
                {(block === 'text' && 'Text Field')
                  || (block === 'image' && 'Image Field')
                  || (block === 'multiline' && 'Multiline Text')}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default AddFieldModal;
