import React from 'react';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import './index.scss';

const NotFound = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { history } = props;

  if (!isAuthenticated) {
    history.push('/');
  }

  return (
    <div className="nucleus--not-found">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  );
};

export default NotFound;
